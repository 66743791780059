<script lang="ts">
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import WithContexts from '../WithContexts.svelte';
  import DesktopCommsMenu from '@svelte/features/comms-menu/desktop/DesktopCommsMenu.svelte';
  import type { GuidedCartContent } from '@svelte/features/comms-menu/components/guided-cart/types';

  export let appNavTree: AppSiteNavWithLocale;
  export let guidedCartContent: GuidedCartContent;
</script>

<WithContexts {appNavTree}
  ><DesktopCommsMenu {guidedCartContent} /></WithContexts
>
