<script lang="ts">
  import TextButton from '@svelte/components/atoms/button/text/TextButton.svelte';
  import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
  import { currentRouteKeyStore } from 'state/stores/nav';
  import { derived } from 'svelte/store';
  import { fade } from 'svelte/transition';

  let visible = derived(
    currentRouteKeyStore,
    route => route === MAIN_NAVIGATION_ROUTER_KEY.MULTIFUNCTIONAL_LANDING
  );
</script>

{#if $visible}
  <div in:fade>
    <TextButton on:click text="Get Yours Now" className="w-rhythm10" />
  </div>
{/if}
