<script lang="ts">
  import { makeClassNames } from 'lib/util';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { fade } from 'svelte/transition';

  export let className: string | null = null;
  export let padding = 'p-rhythm3';
  export let bg = 'bg-pearl-200';
  export let ariaDescription: string;
  const dispatch = createEventDispatcher();

  onMount(() => {
    // TODO: set main content as aria-hidden
    // see: http://web-accessibility.carnegiemuseums.org/code/dialogs/
    document.body.classList.add('overflow-hidden');
  });

  onDestroy(() => {
    document.body.classList.remove('overflow-hidden');
  });
</script>

<div
  aria-describedby="modalDescription"
  role="dialog"
  class="flex-cnt fixed inset-0 z-80 h-screen w-screen"
>
  <div class="sr-only">{ariaDescription}</div>
  <!-- Transition must be made separate or else backdrop only appears at the end of animation -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div
    class="absolute inset-0 h-full w-full backdrop-blur-sm"
    transition:fade={{ duration: 200 }}
    on:click={() => dispatch('close')}
  />
  <div
    transition:fade={{ duration: 200 }}
    class={makeClassNames(
      'max-h-11/12 relative z-10 overflow-y-auto rounded-md border-2 border-primary',
      className,
      padding,
      bg
    )}
  >
    <button
      on:click={() => dispatch('close')}
      class="flex-cnt absolute right-4 top-4 z-10 h-rhythm4 w-rhythm4 rounded-full border border-primary bg-pearl-400 text-f1 font-semibold text-primary transition-colors hover:text-primary-400"
      type="button"
    >
      X
    </button>
    <div class="max-h-rhythm12 overflow-y-auto">
      <slot />
    </div>
    <div class="mt-rhythm0">
      <slot name="footer" />
    </div>
  </div>
</div>
