<script lang="ts">
  import {
    activeStep,
    GuidedCartStep
  } from '@svelte/features/comms-menu/components/guided-cart/state';
  import DesktopModal from '../modal/DesktopModal.svelte';
  import ProductSelection from '@svelte/features/comms-menu/components/guided-cart/ProductSelection.svelte';
  import SizeSelection from '@svelte/features/comms-menu/components/guided-cart/SizeSelection.svelte';
  import StorageSelection from '@svelte/features/comms-menu/components/guided-cart/StorageSelection.svelte';
  import type { GuidedCartContent } from '@svelte/features/comms-menu/components/guided-cart/types';

  export let content: GuidedCartContent;
  export let onClose: () => void;
</script>

<DesktopModal
  on:close={onClose}
  ariaDescription="Pick your offer"
  className="max-w-rhythm12"
>
  {#if $activeStep === GuidedCartStep.Product || $activeStep === GuidedCartStep.Complete}
    <ProductSelection
      heading={content.productHeading}
      headline={content.productHeadline}
      CTAActive={content.productCTAActive}
      CTADisabled={content.productCTADisabled}
    />
  {:else if $activeStep === GuidedCartStep.Variant}
    <SizeSelection
      heading={content.variantHeading}
      headline={content.variantHeadline}
      CTAActive={content.variantCTAActive}
      CTADisabled={content.variantCTADisabled}
    />
  {:else if $activeStep === GuidedCartStep.Offer}
    <StorageSelection
      heading={content.offerHeading}
      headline={content.offerHeadline}
      CTAActive={content.offerCTAActive}
      CTADisabled={content.offerCTADisabled}
      acceptName={content.offerAcceptName}
      acceptDescription={content.offerAcceptDescription}
      declineName={content.offerDeclineName}
      video={content.offerVideo}
      onComplete={onClose}
    />
  {/if}
</DesktopModal>
