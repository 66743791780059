<script lang="ts">
  import ChatIcon from '@svelte/components/atoms/icons/Chat.svelte';
  import ChatwootIntegration from '@svelte/lib/integrations/chatwoot/iframe/ChatwootIntegration.svelte';
  import FloatAction from '../shared/FloatAction.svelte';
  import {
    ChatStatus,
    chatState
  } from '@svelte/lib/integrations/chatwoot/stores';
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { ToggleDetail } from '../types';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { AppEventName } from 'lib/events/contracts';

  export let disabled: boolean;
  const appEventDispatcher = getAppEventDispatcher();
  const dispatch = createEventDispatcher();
  let reset: () => void;
  let open = false;

  const unsubscriptions: Array<() => void> = [
    // Handle user closing chat with button inside iframe
    appEventDispatcher.subscribe(AppEventName.ClosedChat, () => {
      if (open) {
        reset();
        open = false;
      }
    })
  ];

  const handleToggle = ({ detail }: CustomEvent<ToggleDetail>) => {
    if (detail.open && !open) {
      appEventDispatcher.dispatch(AppEventName.OpenedChat);
      open = true;
    }

    if (!detail.open && open) {
      appEventDispatcher.dispatch(AppEventName.ClosedChat);
      open = false;
    }
    dispatch('toggle', detail);
  };

  onDestroy(() => {
    for (const unsubscribe of unsubscriptions) {
      unsubscribe();
    }
  });
</script>

<ChatwootIntegration />
<FloatAction
  bind:reset
  {disabled}
  actionId="chat"
  loading={$chatState === ChatStatus.Loading}
  on:toggle={handleToggle}
>
  <ChatIcon slot="closed" title="Chat with us" />
</FloatAction>
