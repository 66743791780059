export const contentClassNames = [
  'rounded-md',
  'border-4',
  'border-pearl-900',
  'bg-pearl-100',
  'text-primary',
  'p-rhythm2',
  'min-w-rhythm9',
  'material-shadow-4'
];
