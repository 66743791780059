<script lang="ts">
  import InfoBox from '@svelte/components/atoms/info/Box.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { ErrorNotification } from '@svelte/lib/notifications/types';

  export let data: ErrorNotification;
</script>

<InfoBox
  level={data.level}
  withBorders
  className="min-w-rhythm11 mb-rhythm1 last:mb-0 material-shadow-4"
>
  <Paragraph withoutSpacing>
    {data.content}
  </Paragraph>
</InfoBox>
