<script lang="ts">
  import { GuidedCartContent } from '@svelte/features/comms-menu/components/guided-cart/types';
  import GuidedCartCTA from './GuidedCartCTA.svelte';
  import GuidedCartDialog from './GuidedCartDialog.svelte';

  export let guidedCartContent: GuidedCartContent;

  let open = false;
</script>

{#if open}
  <GuidedCartDialog
    onClose={() => (open = false)}
    content={guidedCartContent}
  />
{/if}

<div
  class="fixed bottom-rhythm0 z-20 mx-auto mb-rhythm0 flex w-screen justify-center"
>
  <GuidedCartCTA on:click={() => (open = true)} />
</div>
