<script lang="ts">
  import { areSetsEqual, makeClassNames } from 'lib/util';
  import { fly } from 'svelte/transition';
  import { NotificationButtonState } from './state';
  import { createEventDispatcher } from 'svelte';
  import ActionButton from '../../shared/button/ActionButton.svelte';
  import { ActionButtonState } from '../../shared/button/state';

  export let notificationIds: Set<string>;
  export let state: NotificationButtonState = NotificationButtonState.Active;
  export let animationSecs: number = 3;

  const radius = 36;
  const circumference = radius * 2 * Math.PI;
  const circleOffset = 38;
  const dispatch = createEventDispatcher();
  let animationIds = new Set<string>();
  let animateClassName = '';
  $: isPaused = state === NotificationButtonState.Paused;
  $: isDisabled = state === NotificationButtonState.Disabled;

  let handleDismissal: () => boolean;
  let canToggle = false;
  const handleTimeOut = () => dispatch('timeOut');

  $: {
    if (!areSetsEqual(notificationIds, animationIds)) {
      animationIds = notificationIds;
      // Restart animation
      animateClassName = '';
      // HACK: is there a better way for sync updates?
      setTimeout(() => {
        animateClassName = 'animate-[strokeOffsetToZero_linear_forwards]';
      }, 100);
    }
  }
</script>

<div transition:fly={{ x: '100%' }} on:introend={() => (canToggle = true)}>
  <ActionButton
    withoutBorder
    state={ActionButtonState.Opened}
    {canToggle}
    on:toggle
    bind:toggle={handleDismissal}
  >
    <div
      slot="opened"
      class="relative flex h-full w-full items-center justify-center"
    >
      {#if !isDisabled}
        <svg class="absolute inset-0 h-full w-full -rotate-90">
          <title>Dismissal counter</title>
          <circle
            stroke-width="8"
            stroke="currentColor"
            fill="transparent"
            class={makeClassNames(animateClassName)}
            r={radius}
            cx={circleOffset}
            cy={circleOffset}
            stroke-dasharray={circumference}
            stroke-dashoffset={circumference}
            on:animationend={handleTimeOut}
            style:animation-duration={`${animationSecs}s`}
            style:animation-play-state={isPaused ? 'paused' : 'running'}
          />
        </svg>
      {/if}

      <div class="text-f1 font-semibold">X</div>
    </div>
  </ActionButton>
</div>
