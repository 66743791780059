<script lang="ts">
  import { fade } from 'svelte/transition';
  import { createEventDispatcher, onDestroy } from 'svelte';
  import NotificationButton from './button/NotificationButton.svelte';
  import AddedToCartNotification from '../../../components/notifications/add-to-cart/AddToCartNotification.svelte';
  import NewsletterPrompt from '../../../components/notifications/newsletter-prompt/NewsletterPrompt.svelte';
  import TextNotification from './contents/ErrorPopOver.svelte';
  import { ToggleDetail } from '../types';
  import { derived, get } from 'svelte/store';
  import MenuContents from '../shared/MenuContents.svelte';
  import NotificationHandler from '@svelte/lib/notifications/notificationHandler';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { ValidationWorker } from '@svelte/components/atoms/forms/validation/types';
  import { makeValidationWorker } from '@svelte/components/atoms/forms/validation';
  import { NewsletterFormState } from '@svelte/components/organisms/forms/state';
  import { NotificationButtonState } from './button/state';
  import { contentClassNames } from '@svelte/features/comms-menu/desktop/actions/styles';
  import { makeClassNames } from 'lib/util';
  import { NotificationKind } from '@svelte/lib/notifications/types';

  export let disabled = false;
  export let actionId: string;
  export let validationWorker: ValidationWorker = makeValidationWorker();
  export let newsletterFormState: NewsletterFormState =
    NewsletterFormState.Ready;
  export let appEventDispatcher = getAppEventDispatcher();
  export let handler = new NotificationHandler(appEventDispatcher);

  const dispatch = createEventDispatcher();

  let isActive = false;
  let pauseHandler: NodeJS.Timeout | undefined;

  const errors$ = handler.errors$;
  const nextNotification$ = handler.nextNotification$;
  const notificationIds = derived(
    [errors$, handler.nextNotification$],
    ([errors, next]) =>
      errors.length ? errors.map(e => e.id) : next ? [next.id] : []
  );
  const notificationsInFlight = derived(
    handler.allNotifications$,
    xs => xs.length
  );
  $: notificationButtonState = NotificationButtonState.Active;
  $: nextNotification = $nextNotification$;
  $: newsletterPromptNotification =
    nextNotification?.kind === NotificationKind.NewsletterPrompt
      ? nextNotification
      : null;
  $: currentAddToCartNotification =
    nextNotification?.kind === NotificationKind.AddedToCart
      ? nextNotification
      : null;

  const closeNotifications = async () => {
    if (nextNotification && nextNotification?.kind !== NotificationKind.Error) {
      handler.dismissById(nextNotification.id);
    } else if (get(handler.errors$).length) {
      handler.dismissErrors();
    }
  };

  const handleNotificationsTimeOut = async () => {
    nextNotification && handler.timeOutById(nextNotification.id);
  };

  onDestroy(() => {
    clearTimeout(pauseHandler);
  });

  $: {
    if ($notificationsInFlight && !disabled) {
      isActive = true;
      dispatch('toggle', {
        actionId,
        loading: false,
        open: true
      } as ToggleDetail);
    } else if (!$notificationsInFlight && isActive) {
      isActive = false;
      dispatch('toggle', {
        actionId,
        loading: false,
        open: false
      } as ToggleDetail);
    }
  }
</script>

<li
  transition:fade
  role="menuitem"
  aria-expanded={isActive}
  aria-haspopup="true"
  class="mb-rhythm0"
  on:mouseenter={() => {
    clearTimeout(pauseHandler);
    if (notificationButtonState !== NotificationButtonState.Disabled) {
      notificationButtonState = NotificationButtonState.Paused;
    }
  }}
  on:mouseleave={() => {
    pauseHandler = setTimeout(() => {
      if (notificationButtonState !== NotificationButtonState.Disabled) {
        notificationButtonState = NotificationButtonState.Active;
      }
    }, 200);
  }}
>
  {#if $notificationsInFlight > 0}
    <NotificationButton
      notificationIds={new Set($notificationIds)}
      state={notificationButtonState}
      animationSecs={newsletterPromptNotification ? 8 : 3}
      on:toggle={closeNotifications}
      on:timeOut={handleNotificationsTimeOut}
    />
    <MenuContents>
      <!-- Non-stackable notifications come first in this logic
           so that they can be dismissed by the user
           and there are no race conditions
      -->
      {#if currentAddToCartNotification}
        <AddedToCartNotification
          on:navigation={closeNotifications}
          data={currentAddToCartNotification}
          className={makeClassNames(contentClassNames)}
        />
      {:else if newsletterPromptNotification}
        <NewsletterPrompt
          on:done={closeNotifications}
          on:active={() =>
            (notificationButtonState = NotificationButtonState.Disabled)}
          {validationWorker}
          formState={newsletterFormState}
          notification={newsletterPromptNotification}
          className={makeClassNames(contentClassNames)}
          layout="h"
        />
      {:else}
        {#each $errors$ as error}
          <TextNotification data={error} />
        {/each}
      {/if}
    </MenuContents>
  {/if}
</li>
