<script lang="ts">
  import { productQuickViewSelectedStore } from '@svelte/state/productQuickView';
  import DesktopModal from '../modal/DesktopModal.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { makeProductPath } from 'lib/navigation/dynamic';
  import { useAppNavTree } from '@svelte/data/routes/context';
  import LinkButton from '@svelte/components/atoms/button/link/LinkButton.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import FormattedPrice from '@svelte/components/atoms/product/FormattedPrice/FormattedPrice.svelte';
  import { staticProductData } from '@svelte/data/product/staticProductData';
  import { TextCase } from '@svelte/components/atoms/typography/props';
  import ProductThumbnail from '@svelte/components/atoms/image/product-thumbnail/ProductThumbnail.svelte';
  import ProductRatingInline from '@svelte/components/molecules/product/rating-inline/ProductRatingInline.svelte';

  // export let queryWorker =
  //   disruptorServiceWorkerRemote as DisruptorServiceWorkerAsync;
  const appNavTree = useAppNavTree();
  // const allProducts = queryStore({
  //   key: 'allProducts',
  //   variables: {},
  //   worker: queryWorker
  // });
  // const productData = derived(
  //   [productQuickViewSelectedStore, allProducts],
  //   ([selected, allProducts]) =>
  //     allProducts.data?.allProducts.find(p => p.id === selected?.id)
  // );
  // const image = derived(productData, product => product?.thumbnail);

  $: product = $productQuickViewSelectedStore;
  $: minPrice = product?.minPrice || 0;
  $: maxPrice = product?.maxPrice || 0;
  $: thumbnail = product && staticProductData[product.id];

  const closeModal = () => productQuickViewSelectedStore.set(null);
</script>

{#if product}
  <DesktopModal on:close={closeModal} ariaDescription="Quick Product View">
    <div class="flex items-center space-x-4">
      <div class="mb-rhythm0 h-rhythm8 w-rhythm9">
        {#if thumbnail}
          <!-- TODO: StaticImage requires good data -->
          <!-- <StaticImage width={200} height={200} data={image} /> -->
          <ProductThumbnail productName={product.name} {...thumbnail} />
        {/if}
      </div>

      <div class="flex-1">
        <div>
          <Heading level={3} withoutSpacing textCase={TextCase.None}>
            {product?.name}
          </Heading>
        </div>
        <div>
          <Heading level={4} withoutSpacing textCase={TextCase.None}>
            {product?.familyDescriptor}
          </Heading>
        </div>

        <InterfaceText className="mt-rhythm-3 block" color="text-primary">
          {#if minPrice !== maxPrice}
            <span class="mr-1 text-xs">From</span>
          {/if}
          <FormattedPrice priceIntegerWithDecimals={minPrice} />
        </InterfaceText>
      </div>
    </div>
    <div class="my-rhythm1">
      <ProductRatingInline sku={product.id} preloadedData={product.review} />
    </div>
    <Paragraph className="mb-rhythm-3" bold>
      {product?.descriptor}
    </Paragraph>
    <Paragraph measure="measure">
      {product?.shortDescription}
    </Paragraph>

    <!-- TODO: autofocus -->
    <LinkButton
      slot="footer"
      on:click={closeModal}
      to={makeProductPath($appNavTree, product.pageMetadata.slug)}
      size="s">See product details</LinkButton
    >
  </DesktopModal>
{/if}
