<script lang="ts">
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import DesktopDialog from './dialog/DesktopDialog.svelte';
  import DesktopActionsMenu from './actions/DesktopActionsMenu.svelte';
  import type { GuidedCartContent } from '../components/guided-cart/types';

  export let appEventDispatcher = getAppEventDispatcher();
  export let guidedCartContent: GuidedCartContent;
</script>

<!-- Desktop modals -->
<DesktopDialog {guidedCartContent} />

<!-- Bottom right actions panel -->
<DesktopActionsMenu {appEventDispatcher} />
