<script lang="ts">
  import type { GuidedCartContent } from '../../components/guided-cart/types';
  import GuidedCart from './guided-cart/GuidedCart.svelte';
  import QuickProductView from './quick-product-view/QuickProductView.svelte';

  export let guidedCartContent: GuidedCartContent;
</script>

<QuickProductView />
<GuidedCart {guidedCartContent} />
