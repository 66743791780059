<script lang="ts" context="module">
  import { makeClassNames } from 'lib/util';
  import { createEventDispatcher } from 'svelte';
  import { fly } from 'svelte/transition';
  import CircleLoader from '@svelte/components/atoms/icons/CircleSpinner.svelte';
  import { ActionButtonState } from './state';

  const closedClassNames = ['bg-primary', 'text-secondary', 'border-secondary'];
  const openClassNames = ['bg-pearl-100', 'text-pearl-900', 'border-pearl-900'];
  const slotContainerClassNames = 'h-7 w-7 absolute inset-0 m-auto';
  const disabledContainerClassNames = 'h-7 w-7 absolute inset-0 m-auto';
</script>

<script lang="ts">
  const dispatch = createEventDispatcher();

  export let state: ActionButtonState = ActionButtonState.Closed;
  export let disabled: boolean | null = null;
  export let withoutBorder = false;
  /**
   * canToggle flag is used to prevent issues while transition is happening
   * If user clicks while transition is happening, it will be ignored
   */
  export let canToggle = false;
  export let toggle = () => canToggle && dispatch('toggle');
</script>

<button
  type="button"
  on:click={toggle}
  {disabled}
  class={makeClassNames(
    'overflow-hidden',
    'relative',
    'z-10', // button must be on top of contents when fly transition in progress
    'h-14 w-14',
    'rounded-full',
    !withoutBorder && 'border-2',
    'transition',
    'duration-300',
    'material-shadow-4',
    'disabled:bg-pearl-700',
    'disabled:text-pearl-600',
    'disabled:border-pearl-600',
    'disabled:translate-x-11',
    'transition-transform',
    'duration-500',
    'transform-gpu',
    state === ActionButtonState.Opened ? openClassNames : closedClassNames
  )}
>
  {#if state === ActionButtonState.Loading}
    <div class="flex items-center justify-center">
      <div class={slotContainerClassNames}><CircleLoader /></div>
    </div>
  {:else if state === ActionButtonState.Opened}
    <slot name="opened">
      <div
        transition:fly={{ x: '100%' }}
        on:introend={() => (canToggle = true)}
        on:outrostart={() => (canToggle = false)}
        class="text-f1 font-semibold"
      >
        X
      </div>
    </slot>
  {:else}
    <div
      transition:fly={{ x: '-100%' }}
      on:introend={() => (canToggle = true)}
      on:outrostart={() => (canToggle = false)}
      class={state === ActionButtonState.Closed
        ? slotContainerClassNames
        : disabledContainerClassNames}
    >
      <slot />
    </div>
  {/if}
</button>
